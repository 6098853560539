<template>
  <div class="author-list">
    <b-overlay :show="formBusy" rounded="sm">
      <b-row>
        <b-col cols="12">
          <portlet>
            <template v-slot:body>
              <div class="kt-pagination kt-pagination--brand">
                <div class="kt-pagination__links">
                  <b-pagination
                    class="mb-0"
                    v-if="articleEpisodesMeta.total"
                    v-model="articleEpisodesMeta.current_page"
                    :total-rows="articleEpisodesMeta.total"
                    :per-page="articleEpisodesMeta.per_page"
                    aria-controls="episodes"
                    @change="onChangePage"
                  />
                </div>
                <div class="kt-pagination__toolbar">
                  <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
                    <i class="flaticon2-refresh-arrow"></i>
                  </button>
                  <b-button :to="{name: 'articles.episodes.create', params: {aid}}" class="btn btn-label-success btn-sm border-0">
                    يېڭىدىن قوشۇش
                  </b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="episode in articleEpisodes" md="3" :key="episode.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar>
              <b-form-checkbox v-b-tooltip title="ھالەت تەڭشىكى" :key="`${episode.id}_show`" switch @change="onSwitchShow(episode.id)" v-model="episode.is_show"/>
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="deleteItem(episode.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ episode.title }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">باھاسى</span>
                      <span class="kt-widget__data">{{ episode.need_payment ? episode.price : 'ھەقسىز' }}</span>
                    </div>
                    <div v-if="episode.need_payment" class="kt-widget__contact">
                      <span class="kt-widget__label">كىرىم</span>
                      <span class="kt-widget__data">0 يۈەن</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">كۆرىلىشى: </span>
                      <span class="kt-widget__data">{{ episode.click_times }}</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ياقتۇرغانلار: </span>
                      <span class="kt-widget__data">{{ episode.likes_count }}</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer">
                  <b-button :to="{name: 'articles.episodes.edit', params: {aid, id: episode.id}}" type="button" variant="primary" class="btn-upper">تەھرىرلەش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="articleEpisodes.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئۇچۇر قوشۇڭ
              </p>
            </div>
            <div class="kt-callout__action">
              <b-button :to="{name: 'articles.episodes.create', params: {aid}}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../../partials/widgets/Error";
  import Portlet from "../../../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import articleMixin from "../../../../../mixins/articleMixin";
  import articleEpisodeMixin from "../../../../../mixins/articleEpisodeMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import Session from "@/common/Session";
  import {SET_ARTICLE} from "../../../../../store/types";
  export default {
    name: "index",
    mixins: [ articleMixin, formBusyMixin, articleEpisodeMixin ],
    components: { Error, Portlet },
    computed: {
      ...mapGetters(["article", "articleEpisodes", "articleEpisodesMeta", "articleEpisodeError"]),
      breadTitle(){
        return `${this.article.title}نىڭ بارلىق قىسىملىرى `;
      },
      aid(){
        let { aid } = this.$route.params;
        return aid;
      }
    },
    created() {
      if ( Session.has(`article_${this.aid}`) ){
        this.$store.commit(SET_ARTICLE, Session.get(`article_${this.aid}`));
        this.setBreadCrumb();
      }else {
        this.findArticle(this.aid, false)
          .then(()=>{
            this.setBreadCrumb();
          })
      }
      this.getArticleEpisodes(this.aid);
    },
    mounted() {
      this.setBreadCrumb();
    },
    data(){
      return {
        page: 1,
        formBusy: false,
      };
    },
    methods: {
      setBreadCrumb(){
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: " ئەسەر باشقۇرۇش", route: "/articles/list" },
          { title: this.breadTitle },
        ]);
      },
      onChangePage(page){
        this.page = page;
        this.getArticleEpisodes(this.aid, page);
      },
      refreshList(){
        this.getArticleEpisodes(this.aid);
      },
      deleteItem(id) {
        this.$bvModal.msgBoxConfirm('مەزكۇر ئەسەرنى ئ‍ۆچۈرسىڭىز ئەسلىگە قايتۇرغىلى بولمايدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟', {
          title: 'راستىنلا ئۆچۈرەمسىز ؟',
          okVariant: 'danger',
          okTitle: 'جەزىملەشتۈرىمەن',
          cancelTitle: 'ياق',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if ( ! value ) return;
          this.deleteArticleEpisode(this.aid, id)
            .then(()=>{
              this.getArticleEpisodes(this.aid);
            })
        })
        .catch(err => {
          // An error occurred
        })
      },
      onSwitchShow(e){
        this.switchArticleEpisodeState(this.aid, e, {column: 'is_show'});
      }
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
